interface BuildLocaleStringOptions {
  language?: string;
  country?: string;
}

export default (options?: BuildLocaleStringOptions) => {
  const language =
    options?.language ||
    (import.meta.env.VITE_DEFAULT_LANGUAGE as string | undefined);
  const country =
    options?.country || (import.meta.env.VITE_COUNTRY as string | undefined);

  if (typeof language === 'undefined' || language.length === 0) {
    throw new Error(
      `Cannot build locale string because no language key was provided and VITE_APP_DEFAULT_LANGUAGE environment variable is not set.`,
    );
  }
  if (typeof country === 'undefined' || country.length === 0) {
    throw new Error(
      `Cannot build locale string because no country key was provided and VITE_APP_BRAND_CODE environment variable is not set.`,
    );
  }

  return `${language.toLowerCase()}-${country.toUpperCase()}`;
};
