import deDE from './de-DE.json';
import esES from './es-ES.json';
import frBE from './fr-BE.json';
import frFR from './fr-FR.json';
import itIT from './it-IT.json';
import nlBE from './nl-BE.json';

export default {
  'de-DE': deDE,
  'es-ES': esES,
  'fr-BE': frBE,
  'fr-FR': frFR,
  'it-IT': itIT,
  'nl-BE': nlBE,
};
