import { createRouter, createWebHistory } from 'vue-router';
import isEmpty from 'lodash/isEmpty';
import merge from 'lodash/merge';
import isEqual from 'lodash/isEqual';
import { RouteNames } from '@/types';
import i18n from './i18n';
import { buildLocaleString } from './helpers';
import { I18n as AmplifyI18n } from 'aws-amplify';

// Parse environment variables pertaining to multiple locales handling
const countryCode = import.meta.env.VITE_COUNTRY as string;
const expectedLocales = (
  import.meta.env.VITE_APP_LOCALES as string | undefined
)?.split(',');
const defaultLocale = import.meta.env.VITE_DEFAULT_LANGUAGE as
  | string
  | undefined;
const languagePrefixTemplate =
  typeof expectedLocales === 'undefined'
    ? ''
    : `/:lang(${expectedLocales.join('|')})?`;

// Define router configuration
const routes = [
  {
    path: languagePrefixTemplate || '/',
    children: [
      {
        path: 'signin',
        name: RouteNames.SIGN_IN,
        meta: { title: 'SignIn' },
        component: () => import('./views/MainPage.vue'),
      },
      {
        path: 'signout',
        name: RouteNames.SIGN_OUT,
        meta: { title: 'SignOut' },
        component: () => import('./views/MainPage.vue'),
      },
      {
        path: 'signup',
        children: [
          {
            path: '',
            name: RouteNames.SIGN_UP,
            meta: { title: 'SignUp' },
            component: () => import('./views/MainPage.vue'),
          },
          {
            path: 'success',
            name: RouteNames.SIGN_UP_SUCCESS,
            meta: { title: 'Success' },
            component: () => import('./views/sign-up/SuccessPage.vue'),
          },
        ],
      },
      {
        path: 'error',
        name: RouteNames.ERROR,
        meta: { title: 'Error' },
        component: () => import('./views/ErrorPage.vue'),
      },
      {
        path: 'reset-password',
        name: RouteNames.RESET_PASSWORD,
        meta: { title: 'ResetPassword' },
        component: () => import('./views/MainPage.vue'),
      },
      {
        path: '',
        redirect: '/signin',
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  const language: string | undefined = Array.isArray(to.params.lang)
    ? to.params.lang[0]
    : to.params.lang;
  // If a list of expected locales has been defined and path accepts a locale prefix
  if (
    typeof expectedLocales !== 'undefined' &&
    expectedLocales.length > 0 &&
    typeof to.params.lang !== 'undefined'
  ) {
    // If prefix locale has been provided
    if (to.params.lang.length > 0) {
      // Change language in i18n if needed
      const newLanguageCode = buildLocaleString({ language });
      if (i18n.global.locale.value !== newLanguageCode) {
        AmplifyI18n.setLanguage(newLanguageCode);
        i18n.global.locale.value = newLanguageCode;
      }
      // If prefix locale hasn't been provided
    } else {
      // If not default locale was specified in the configuration, throw an error
      if (typeof defaultLocale === 'undefined') {
        throw new Error(
          `No locale prefix has been provided in path "${
            to.fullPath
          }" while one of ${JSON.stringify(
            expectedLocales,
          )} was expected, and no fallback locale was specified as well.`,
        );
      }
      // Otherwise, redirect to same path prepended with default locale prefix
      const name = to.name;
      return next({ name, params: { lang: defaultLocale } });
    }
  }

  if (from?.query && !isEmpty(from.query) && !isEqual(from.query, to.query)) {
    next(merge(to, { query: from.query }));
  } else {
    next();
  }
});

export default router;
