import { createI18n } from 'vue-i18n';
// AWS amplify
import { I18n as AmplifyI18n } from 'aws-amplify';
import { buildLocaleString } from './helpers';
import translations from '@/i18n/translations';

const theme = import.meta.env.VITE_BRAND_THEME as
  | 'santevet'
  | 'jimjoe'
  | 'bullebleue';
const messages = translations[theme];
if (typeof messages === 'undefined') {
  throw new Error(
    `Brand theme "${theme}" has no existing translations (expected: "santevet", "jimjoe" or "bullebleue").`,
  );
}

const locale = buildLocaleString();

// Set default language and translations for Amplify toolkit
AmplifyI18n.setLanguage(locale);
AmplifyI18n.putVocabularies(messages);

const i18n = createI18n({
  legacy: false, /// Use Composition API
  locale,
  messages,
  globalInjection: true,
});

export default i18n;
